import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { useState } from "react";
import axios from '../api/axios';
import './style/Login.css';
import useAuth from '../hooks/useAuth';
import {useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

const Login = () => {

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const  [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [isLogin, setIsLogin] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!username || !password){
      return;
    }

    setIsLogin(true);

    try{
      const result = await axios.post(
        '/login',
        JSON.stringify({ "username": username, 
                          "password": password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
                )

      const accessToken = result?.data?.accessToken;

      setAuth({ accessToken });
      navigate(from, { replace: true });

    }catch(err){

      if (err.response?.status === 401) {
        toast.error("Username or Password incorrect.");
      }else if(err.response?.status === 403){
        toast.error("Email not confirmed. 📨");
       }else{

      }
    }
    setIsLogin(false);
}
   
          return (
            <div className="wrapper">
              <form action="">
                <h1>Welcome !</h1>
                <div className="input-box">
                  <input 
                        type="text" 
                        placeholder='Username' 
                        value={username}
                        onChange={ (e) => setUsername(e.target.value)}
                        required/>
                  <FaUser className='icon'/>
                </div>
                <div className="input-box">
                  <input 
                        type="password" 
                        placeholder='Password' 
                        value={password}
                        onChange={ (e) => setPassword(e.target.value)}
                        required/>
                  <FaLock className='icon'/>
                </div>

                <div className="remember-forgot">
                  <label><input type="checkbox"/>Remember me</label>
                  <a href='/reset' className='forgotpassword'>Forgot password?</a>
                </div>

                {!isLogin && (
                  <>
                  <button type="submit" onClick={handleSubmit} className="login">Login</button>
                  <div className="register-link">
                      <p>Don't have an account? <a href='/signup'>Register</a></p>
                  </div>        
                  </>
                  )}

                  {/* Wrap ClipLoader in a div with className "spinner-container" */}
                  {isLogin && (
                    <div className="spinner-container">
                      <ClipLoader color="#2788e8" className="spinner"/>
                    </div>
                  )}    

              </form>
              <ToastContainer position="bottom-right"/>
            </div>
          )
  }

export default Login;
