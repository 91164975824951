// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

  
  .wrapper .input-box-token {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0px;
  }
  
  .input-box-token input {
    width: 115%;
    height: 100%;
    background: transparent;
    outline: none;
    border: 2px solid rgba(39, 136, 232, .3);
    border-radius: 40px;
    font-size: 16px;
    padding-left: 30px; /* Start the text 10px from the left */
    padding-right: 30px; /* End the text 10px from the right */
    box-sizing: border-box; /* Include padding in the width calculation */
  }
  
  .input-box-token input::placeholder {
    color: #2788e8;
  }
  
  .input-box-token .icon {
    position: absolute;
    right: -35px;
    top: 55%;
    transform: translateY(-65%);
    font-size: 16px;
  }
  


  `, "",{"version":3,"sources":["webpack://./src/components/style/Token.css"],"names":[],"mappings":";;;EAGE;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb,wCAAwC;IACxC,mBAAmB;IACnB,eAAe;IACf,kBAAkB,EAAE,sCAAsC;IAC1D,mBAAmB,EAAE,qCAAqC;IAC1D,sBAAsB,EAAE,6CAA6C;EACvE;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,QAAQ;IACR,2BAA2B;IAC3B,eAAe;EACjB","sourcesContent":["\n\n  \n  .wrapper .input-box-token {\n    position: relative;\n    width: 100%;\n    height: 50px;\n    margin: 30px 0px;\n  }\n  \n  .input-box-token input {\n    width: 115%;\n    height: 100%;\n    background: transparent;\n    outline: none;\n    border: 2px solid rgba(39, 136, 232, .3);\n    border-radius: 40px;\n    font-size: 16px;\n    padding-left: 30px; /* Start the text 10px from the left */\n    padding-right: 30px; /* End the text 10px from the right */\n    box-sizing: border-box; /* Include padding in the width calculation */\n  }\n  \n  .input-box-token input::placeholder {\n    color: #2788e8;\n  }\n  \n  .input-box-token .icon {\n    position: absolute;\n    right: -35px;\n    top: 55%;\n    transform: translateY(-65%);\n    font-size: 16px;\n  }\n  \n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
