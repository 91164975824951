import { useState } from "react";
import { FaLock } from "react-icons/fa";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Settings = () => {

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordValid, setNewPasswordValid] = useState('');

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const t1 = PWD_REGEX.test(newPassword);
        const t2 = newPassword === newPasswordValid;

        if(!t1){
            toast.warning("Password needs 8-24 chars with a mix of upper/lowercase letters, a number, and a special char (!@#$%).");
            return;
        }
        
        if(!t2){
            toast.error("Passwords do not match. 🤯");
            return;
        }

        try{
            const result = await axiosPrivate.post(
                'user/updatePwd',
                {password,newPassword},
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                        })
            console.log(result);
            toast.success("Password Update 🥰");
        }catch(err){
            if (err.response?.status === 401) {
                toast.error('The password you entered is incorrect. Please try again. 🧐')
            }else{
                navigate('/login', { state: { from: location }, replace: true });
            }
            console.error(err.message);
        }
  
    }
   
          return (
            <div className="wrapper">
            <form action="">
              <h1>Settings</h1>
                    <div className="input-box">
                      <input 
                              type="password" 
                              placeholder='Password' 
                              value={password}
                              onChange={ (e) => setPassword(e.target.value)}
                              required/>
                      <FaLock className='icon'/>
                    </div>

                    <div className="input-box">
                      <input 
                              type="password" 
                              placeholder='New Password' 
                              value={newPassword}
                              onChange={ (e) => setNewPassword(e.target.value)}
                              required/>
                      <FaLock className='icon'/>
                    </div>
                
                    <div className="input-box">
                      <input 
                              type="password" 
                              value={newPasswordValid}
                              onChange={ (e) => setNewPasswordValid(e.target.value)}
                              placeholder='Confirm New Password' 
                              required/>
                      <FaLock className='icon'/>
                    </div>

              <button type="submit" className="login" onClick={handleSubmit}>Change Password</button>

            </form>
            <ToastContainer position="bottom-right"/>
          </div>
         
          );
}
export default Settings;
