import { AiOutlineDollar } from "react-icons/ai";
import useAuth from "../../hooks/useAuth";
import { useState,useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/Deposit.css'; // Import the CSS file
import ClipLoader from "react-spinners/ClipLoader";
import bitcoinImageQR from "../../img/qrcode-bitcoin.png";

const Bitcoin = () => {
    const {setAuth} = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [usdAmount, setUsdAmount] = useState(0);
    const [ifCreateInvoice, setIfCreateInvoice] = useState(false);
    const [invoiceCreated, setInvoiceCreated] = useState(false);
    const [coinAmount, setCoinAmount] = useState(0);
    const [invoiceId, setInvoiceId] = useState(null);

    const [thankYou, setThankYou] = useState(false);

    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
      let interval;
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);
      } else if (seconds === 0 && invoiceCreated) {
        setInvoiceCreated(false)
        cancelInvoice();
      }
    
      return () => clearInterval(interval);
    }, [seconds,invoiceCreated]);

    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleUsdAmount = event => {
      setUsdAmount(event.target.value); 
    };
      
      

    const createInvoice = async (e) => {
        
        setIfCreateInvoice(true);
  
        if(!usdAmount){
          toast.error("USD Amount required.");
          setIfCreateInvoice(false);
          return;
        }


        try {
            
            const response = await axiosPrivate.post('/invoice/create',{coinType:"bitcoin", usdAmount});
            setCoinAmount(response.data.coinAmount);
            setInvoiceId(response.data._id)
        } catch (err) {
            if(err.response?.status === 400 || err.response?.status === 409){
              toast.error("Oops! Unable to create invoice. Please contact our support team.");
              setIfCreateInvoice(false);
              return;
            }else{
              setAuth({})
              navigate('/login', { state: { from: location }, replace: true });
            }
        }

        setInvoiceCreated(true);
        setSeconds(3600);

    }

    const cancelInvoice = async (e) => {
        e.preventDefault();
        
        try {
          await axiosPrivate.post('/invoice/cancel',{invoiceId});
          setIfCreateInvoice(false);
          setInvoiceCreated(false);
          setSeconds(0);
        } catch (err) {}
    }

    useEffect(() => {
      if(ifCreateInvoice && invoiceCreated){
        const intervalId = setInterval(checkInvoice, 10000); 
    
        return () => clearInterval(intervalId); 
      }
    }, [ifCreateInvoice,invoiceCreated]);

    const checkInvoice = async () => {

      try {
        const result = await axiosPrivate.post('/invoice/check',{invoiceId});
        console.log(result)
        if (result.status === 200){
          setThankYou(true);
          setIfCreateInvoice(false);
          setInvoiceCreated(false);
        }

      } catch (err) {}
    }

    return (
  <div className="wrapper">
    <form action="">
      

      <table style={{"padding":"0px"}}>
            <tr key="qrcode">
              <div className="wallet">
                  <td>
                    <h1>BITCOIN</h1>
                    { thankYou && (
                        <>
                            <br></br><br></br>
                            <b>Thank you for your deposit! 
                            <br></br>We've successfully added ${usdAmount} to your account.</b>
                        </>
                    )
                    }

                    { !thankYou && !ifCreateInvoice && !invoiceCreated && (
                      <>
                        <div className="input-box">
                            <input type="text" id="transactionIdText" onChange={handleUsdAmount} placeholder="Amount in USD ?" required/>
                            <AiOutlineDollar className='icon' />
                        </div>
                        <button type="submit" className="login" onClick={createInvoice}>Create invoice</button>
                        <div className="need-help">
                          <center><a href='https://t.me/smsland' target="_blank">Do you Need Help ?</a></center>
                        </div>
                      </>
                    )

                    }

                    {/* Wrap ClipLoader in a div with className "spinner-container" */}
                    {!thankYou && ifCreateInvoice && !invoiceCreated && (
                      <>
                        <br></br>
                        <div className="spinner-container">
                        <ClipLoader color="#2788e8" className="spinner"/>
                        </div>
                      </>
                    )}

                    {!thankYou && invoiceCreated && (
                      <>
                          <table style={{"padding":"40px"}}>
                              <tr key="qrcode" style={{"padding":"0px 40px"}}>
                                <div className="wallet">
                                    <td>
                                      BTC: <b>bc1qgd3dc4lp2c337du2j2dw8hy2dw4hlhm5rmtkmt</b>
                                      <br/><br/>
                                      <b style={{"color":"red"}}>Please transfer the exact amount of {coinAmount} BTC to the specified address. </b><br></br>
                                      This payment link will remain active for 1 hour only. <br></br>
                                      Act promptly to ensure your transaction is processed.
                                      <br>
                                      </br>
                                      <br>
                                      </br>
                                      Time left: {formatTime(seconds)} <br></br>
                                    </td>
                                </div>
                                
                                <td style={{"padding":"0px 40px"}}>
                                  <div className="wallet">
                                    <center>
                                    <img src={bitcoinImageQR} alt="BITCOIN - QR" />
                                    <b>Scan it !</b>
                                    </center>
                                  </div>
                                </td>
                              </tr>
                        </table>

                        <div style={{"textAlign": "center", "width": "100%"}}>
                              <button type="submit" className="cancel" onClick={(e) => cancelInvoice(e)}>Cancel invoice</button>
                              <div className="need-help2">
                                  <a href='https://t.me/smsland' target="_blank">Do you Need Help?</a>
                              </div>
                        </div>
                      
                      </>
                    )

                    }




                  </td>
              </div>
              
            </tr>
      </table>

      
    </form>
    <ToastContainer position="bottom-right"/>
  </div>
);

}
export default Bitcoin;
