import { useState, useEffect } from "react";

const SignUpEmailSent = ({ email }) => {
    const [showEmail, setShowEmail] = useState('');

    useEffect(() => {
        setShowEmail(email);
    }, [email]); 

    return (
        <div>
            
                A link to confirm your account has been sent to:<b> {showEmail}</b>. 
                <br/>
                If you don't see it in your inbox, please check your spam folder.
            
        </div>
    );
}

export default SignUpEmailSent;
