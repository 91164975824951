import { FaCopy } from "react-icons/fa";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style/Token.css'; // Import the CSS file

const Token = () => {

    const {setAuth} = useAuth();
    const [token, setToken] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const copy = async () => {
        try {
            console.log(token);
            await navigator.clipboard.writeText(token);
            console.log('Text copied to clipboard');
            toast('Copied to clipboard');
          } catch (err) {
            console.error('Failed to copy: ', err);
          }
    }

    const getToken = async () => {
        try {
            const response = await axiosPrivate.get('/user/getme');
            console.log(response.data);
            setToken(response.data.tokenAPI);
            
        } catch (err) {
            console.error(err);
            setAuth({})
            navigate('/login', { state: { from: location }, replace: true });
        }
    }

    const revoke = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosPrivate.get('/user/revoke');
            console.log(response.data);
        } catch (err) {
            console.error(err);
            setAuth({})
            navigate('/login', { state: { from: location }, replace: true });
        }

        getToken();
    } 

    getToken();
    return (
        <div className="wrapper">
        <form action="">
          <h1>Your Access Token</h1>
          <div className="input-box-token">
            <input type="text" placeholder={token} disabled/>
            <FaCopy className='icon' onClick={copy}/>
          </div>

          <button type="submit" className="login" onClick={revoke}>Revoke</button>

        </form>
      <ToastContainer position="bottom-right"/>
      </div>
      );
}
export default Token;
