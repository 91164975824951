import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import axios from '../api/axios';
import { useState } from "react";
import SignUpEmailSent from "./SignUpEmailSent";
import { ToastContainer, toast } from 'react-toastify';




const SignUp = () => {

  const USER_REGEX = /^[A-z][A-z0-9-_]{5,20}$/;
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const  [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
      e.preventDefault();

      const t0 = USER_REGEX.test(username);
      const t1 = EMAIL_REGEX.test(email);
      const t2 = PWD_REGEX.test(password);
      const t3 = passwordValid === password;


      if(!t0){
        toast.warning("Username needs 5-20 letters, start with a letter, include numbers.");
        return;
      }

      if(!t1){
        toast.error("Email address invalid. 🤯");
        return;
      }

      if(!t2){
        toast.warning("Password needs 8-24 chars with a mix of upper/lowercase letters, a number, and a special char (!@#$%).");
        return;
      }
            
      if(!t3){
        toast.error("Passwords do not match. 🤯");
        return;
      }

      try{
        const result = await axios.post(
          '/register',
          JSON.stringify({ "username": username, 
                            "email": email, 
                            "password": password }),
                  {
                      headers: { 'Content-Type': 'application/json' },
                      withCredentials: true
                  }
                  )
        setSuccess(true);
      }catch(err){
        if (err.response?.status === 409) {
          toast.error(err.response.data.message);
        }else{

        }
      }
    
  }
   
          return (
            <>
                {success ? (
                    <section>
                       <SignUpEmailSent email={email} />
                    </section>
                ) : (
                  <div className="wrapper">
                  <form action="">
                    <h1>Sign Up !</h1>
                    <div className="input-box">
                      <input 
                              type="text" 
                              placeholder='Username' 
                              value={username}
                              onChange={ (e) => setUsername(e.target.value) }
                              required/>
                      <FaUser className='icon'/>
                    </div>
                    <div className="input-box">
                      <input 
                              type="text" 
                              placeholder='Email' 
                              value={email}
                              onChange={ (e) => setEmail(e.target.value)}
                              required/>
                      <MdEmail className='icon'/>
                    </div>
                    <div className="input-box">
                      <input 
                              type="password" 
                              placeholder='Password' 
                              value={password}
                              onChange={ (e) => setPassword(e.target.value)}
                              required/>
                      <FaLock className='icon'/>
                    </div>
                
                    <div className="input-box">
                      <input 
                              type="password" 
                              value={passwordValid}
                              onChange={ (e) => setPasswordValid(e.target.value)}
                              placeholder='Confirm Password' 
                              required/>
                      <FaLock className='icon'/>
                    </div>
                
                    <button type="submit" onClick={handleSubmit} className="login">Sign Up</button>
                
                    <div className="register-link">
                      <p>Are you already registered? <a href='/login'>Login</a></p>
                    </div>
                
                  </form>
                  <ToastContainer position="bottom-right"/>
                </div>
                )}
            </>
        )
    }
    

export default SignUp;


