import React from 'react';
import './style/API.css'; // Import the CSS file

const API = () => {
    const token = '$token';
    const service = '$service';
    const country = '$country';
    const url = "api.smsland.org"
    return (
        <div className="api-documentation">
            <h1>API Documentation</h1>

            <section>
                <h2>Get Balance</h2>
                <p>This endpoint retrieves the current balance.</p>
                <h3>HTTP GET</h3>
                <code>GET /getBalance?token={token}</code>

                <h3>Parameters</h3>
                <ul>
                    <li><strong>token</strong> (required) - Your API token for authentication.</li>
                </ul>

                <h3>Example Request</h3>
                <code>GET {url}/getBalance?token={token}</code>

                <h3>Example Response</h3>
                <pre>{`{
    "balance": 100
}`}</pre>
            </section>

            <section>
                <h2>Get Number</h2>
                <p>This endpoint retrieves a number based on the specified service and country.</p>
                <h3>HTTP GET</h3>
                <code>GET /getNumber?token={token}&service={service}&country={country}</code>

                <h3>Parameters</h3>
                <ul>
                    <li><strong>token</strong> (required) - Your API token for authentication.</li>
                    <li><strong>service</strong> (required) - The service for which you want to retrieve a number.</li>
                    <li><strong>country</strong> (optional) - The country for which you want to retrieve a number. The default id random country.</li>
                </ul>

                <h3>Example Request</h3>
                <code>GET {url}/getNumber?token={token}&service={service}&country={country}</code>

                <h3>Example Response</h3>
                <pre>{`{
      "taskId":"6611a1f1a4a5dfd8149e4fff",
      "number":"790754383",
      "country":"russia",
      "service":"telegram",
      "price":0.2
}`}</pre>
            </section>

            <section>
                <h2>Get SMS</h2>
                <p>This endpoint retrieves a number based on the specified service and country.</p>
                <h3>HTTP GET</h3>
                <code>GET /getCode?token={token}&taskId=$taskId</code>

                <h3>Parameters</h3>
                <ul>
                    <li><strong>token</strong> (required) - Your API token for authentication.</li>
                    <li><strong>taskId</strong> (required) - The service for which you want to retrieve a number.</li>
                </ul>

                <h3>Example Request</h3>
                <code>GET {url}/getCode?token={token}&taskId=$taskId</code>

                <h3>Example Response</h3>
                <pre>{`{
      "number":"790754383",
      "status":"pending",
      "code":null
}`}</pre>
            </section>

            <section>
                <h2>Cancel Order</h2>
                <p>This endpoint retrieves a number based on the specified service and country.</p>
                <h3>HTTP GET</h3>
                <code>GET /cancelOrder?token={token}&taskId=$taskId</code>

                <h3>Parameters</h3>
                <ul>
                    <li><strong>token</strong> (required) - Your API token for authentication.</li>
                    <li><strong>taskId</strong> (required) - The service for which you want to retrieve a number.</li>
                </ul>

                <h3>Example Request</h3>
                <code>GET {url}/getCode?token={token}&taskId=$taskId</code>

                <h3>Example Response</h3>
                <pre>{`{
      "message":"Order cancellation was successful."
}`}</pre>
            </section>

        </div>
    );
};

export default API;
