import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./hooks/useAuth";

const RequireNotAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();

    const isAuthenticated = auth?.accessToken;

    if (isAuthenticated && location.pathname === '/login') {
        return <Navigate to="/" replace />;
    }
    return (
        isAuthenticated
            ? <Navigate to="/" state={{ from: location }} replace />
            : <Outlet />
    );
}

export default RequireNotAuth;