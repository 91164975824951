import SignUp from './components/SignUp';
import Home from './components/Home';
import Deposit from './components/Deposit';
import VirtualNumbers from './components/VirtualNumbers';
import API from './components/API';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import Layout from './Layout';
import RequireAuth from './RequireAuth';
import RequireNotAuth from './RequireNotAuth';
import Token from './components/Token';
import Orders from './components/Orders';
import { Routes, Route } from 'react-router-dom';
import Settings from './components/Settings';
import { Navigate } from 'react-router-dom';
import PersistLogin from './components/PersistLogin';
import EmailConfirm from './components/EmailConfirm';
import Ton from './components/deposit/Ton';
import Bitcoin from './components/deposit/Bitcoin';
import UsdtTrc20 from './components/deposit/UsdtTrc20';
import ResetPasswordEmail from './components/ResetPasswordEmail';

function App() {
  return (
    <>
      
      <Routes>
      
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route element={<PersistLogin />}>
          <Route path="/" element={<Home />} />
          <Route path="api" element={<API />} />
          <Route path="confirm-account" element={<EmailConfirm />} />
          <Route path="reset-password" element={<ResetPasswordEmail />} />

          <Route element={<RequireNotAuth />} >
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="reset" element={<ResetPassword />} />
          </Route>

          {/* protected routes */}
            <Route element={<RequireAuth />}>
                <Route path="deposit" element={<Deposit />} />
                <Route path="usdt" element={<UsdtTrc20 />} />
                <Route path="ton" element={<Ton />} />
                <Route path="bitcoin" element={<Bitcoin />} />
                <Route path="virtualnumbers" element={<VirtualNumbers />} />
                <Route path="token" element={<Token />} />
                <Route path="orders" element={<Orders />} />
                <Route path="settings" element={<Settings />} />
            </Route>
           

          <Route path="*" element={<Navigate to="/" replace />} />
          </Route>   
        </Route>
        

      </Routes>
    </>
  );
}

export default App;
