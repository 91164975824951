import { useContext } from "react";
import BalanceContext from "../context/BalanceProvider";

const useBalance = () => {
    const context = useContext(BalanceContext);
    //console.log(context); // Log the context value to debug
  
    if (context === undefined) {
      throw new Error('useBalance must be used within a BalanceProvider');
    }
  
    const { balance, setBalance } = context;
  
    return { balance, setBalance };
  }
  
  export default useBalance;