import { useState, useEffect } from "react";
import { FaLock } from "react-icons/fa";
import axios from '../api/axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const ResetPasswordEmail = () => {

    const [password, setPassword] = useState('');
    const [expaired, setExpaired] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [key,setKey] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const k = urlParams.get('key');

        if(k) setKey(k);
    }, []);
    
    useEffect(() => {

        if (key) {
            const checkConfirmEmail = async () => {
                try {
                    await axios.get(`/reset-password?key=${key}`,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });
                } catch (err) {
                    console.error(err);
                    setExpaired(true);
                }
            };
    
            checkConfirmEmail();
        } else {
            // If key is null or undefined, you may want to handle this case as well, e.g.,
            //navigate('/login', { state: { from: location }, replace: true });
        }
    }, [key]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const t1 = PWD_REGEX.test(password);
        const t2 = password === confirmPassword;

        if(!t1){
            toast.warning("Password needs 8-24 chars with a mix of upper/lowercase letters, a number, and a special char (!@#$%).");
            return;
        }
        
        if(!t2){
            toast.error("Passwords do not match. 🤯");
            return;
        }

        try{
            const result = await axios.put(
                'reset-password',
                {key,password},
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                        })
            console.log(result);
            toast.success("Password Update 🥰");

            const sleep = (milliseconds) => {
                return new Promise(resolve => setTimeout(resolve, milliseconds));
              };
            await sleep(3000);

            navigate('/login', { state: { from: location }, replace: true });
        }catch(err){
            navigate('/login', { state: { from: location }, replace: true });
            console.error(err.message);
        }
  
    }

    if (!expaired){
        return (
            <div className="wrapper">
            <form action="">
              <h1>Settings</h1>
                    <div className="input-box">
                      <input 
                              type="password" 
                              placeholder='Password' 
                              value={password}
                              onChange={ (e) => setPassword(e.target.value)}
                              required/>
                      <FaLock className='icon'/>
                    </div>

                    <div className="input-box">
                      <input 
                              type="password" 
                              placeholder='Confirm Password' 
                              value={confirmPassword}
                              onChange={ (e) => setConfirmPassword(e.target.value)}
                              required/>
                      <FaLock className='icon'/>
                    </div>

              <button type="submit" className="login" onClick={handleSubmit}>Change Password</button>

            </form>
            <ToastContainer position="bottom-right"/>
          </div>
         
          );
    }else {
        toast.error("Reset password expaired. 🤯");
        return <ToastContainer position="bottom-right"/>;
    }
          
}
export default ResetPasswordEmail;
