import { useState, useEffect } from "react";
import axios from '../api/axios';

const EmailConfirm = () => {
    const [confirmed, setConfirmed] = useState(false);

    useEffect(() => {
        const checkConfirmEmail = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const key = urlParams.get('key'); 

            if(key) { 
                try {
                    await axios.get(`/confirm-account?key=${key}`);
                    setConfirmed(true);
                    
                } catch (err) {
                    console.error(err);
                }
            }
        };

        checkConfirmEmail();
    }, []); // Empty dependency array means this effect runs once after the initial render

    if(confirmed){
        return (
            <div>
                <p>Your email has been successfully confirmed! You're all set to start using our services.</p>
            </div>
        );
    }else{
        return (
            <div>
                <p>Link access not found 😳.</p>
            </div>
        );
    }
}

export default EmailConfirm;
