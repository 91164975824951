import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import './style/VirtualNumbers.css';
import { FaCopy } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import codeArrivedSound from "../sounds/arrived_code_sound.wav";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ReactCountryFlag from "react-country-flag"
import useBalance from "../hooks/useBalance";

const sound = new Audio(codeArrivedSound);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
  padding: '8px',
};

const cellStyle = {
  width: '50%',
  border: '1px solid',
  textAlign: 'center',
  padding: '8px'
};

const LoadStyle = {
  width: '50%',
  textAlign: 'center',
  padding: '8px'
};

const copy = async (val) => {
  try {
      await navigator.clipboard.writeText(val);
      toast('Copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
}


const VirtualNumbers = () => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const {setBalance} = useBalance();

  const [codeArrived, setCodeArrived] = useState(false);
  const [buyNumber, setBuyNumber] = useState(false);
  const [buyNumberButton, setbuyNumberButton] = useState(false);
  const [country, setCountry] = useState();
  const [price, setPrice] = useState();
  const [status, setStatus] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [code,setCode] = useState();
  const [idTask, setIdTask]  = useState();
  const [autoCancel, setAutoCancel] = useState(false);
  const [timeOutCancel, setTimeOutCancel] = useState(false);

  const [optionsChannel, setOptionChannel] = useState([])
  const [optionsService, setOptionService] = useState([])
  const [optionsCountry, setOptionsCountry] = useState([])


  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleChannelChange = selectedOption => {
    setSelectedChannel(selectedOption.value);
  };

  const handleServiceChange = selectedOption => {
    setSelectedService(selectedOption.value);
  };

  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption.value);
  };

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0 && buyNumber) {
      setAutoCancel(true);
      cancel(false);
      setTimeOutCancel(true);
    }
  
    return () => clearInterval(interval);
  }, [seconds,buyNumber]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (codeArrived && !isSafari) {
      sound.play();
      toast('Code Arrived!');
    }
  }, [codeArrived]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCh = await axiosPrivate.get('data/channels');
        const channels = responseCh.data;
        let tempChannels = []; 

        channels.channels.forEach(channel => {
    
          tempChannels.push({ value: channel.value, label:channel.name });
        });


        const responseS = await axiosPrivate.get('data/services');
        const services = responseS.data;
        let tempServices = []; 

        services.services.forEach(service => {
          const imagePath = require(`../img/services-icon/icons8-${service.name.toLowerCase()}-48.png`);

          tempServices.push({ value: service.name.toLowerCase(), label: (
              <>
                {service.name}
                <img className="service-icon" src={imagePath} alt={`${service.name} icon`} />
              </>
          ) });
        });



        const responseC = await axiosPrivate.get('data/countries');
        const countries = responseC.data;
        let tempCountries = []; 

        countries.countries.forEach(country => {
          tempCountries.push(
            { value: country.name.toLowerCase(), label: (
              <>
                {country.name} <ReactCountryFlag countryCode={country.code} svg />
              </>
            )}
          );
        });
        
        setOptionChannel(tempChannels);
        setOptionService(tempServices);
        setOptionsCountry(tempCountries);

      } catch (error) {
        console.error("There was an error fetching the data:", error);
        // Handle the error appropriately in your UI
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if(buyNumber && !timeOutCancel){
      const intervalId = setInterval(checkCode, 5000); 
  
      return () => clearInterval(intervalId); 
    }
  }, [buyNumber,!timeOutCancel]);

  const checkCode = async () => {


    try{
      const response = await axiosPrivate.post('/order/code',
                    {
                      "id": idTask
                    });
      if(response.data.code && response.data.status === "complete"){
        setCodeArrived(true);
        setbuyNumberButton(false);
        setCode(response.data.code);
        setStatus("Complete ✅");
      }else if(response.data.status === "cancelled"){
        setAutoCancel(true);
        cancel(false);
        setTimeOutCancel(true);
      }else{
        //console.log(response.data);
      }

        
    }catch(err){
      //console.error(err.message);
    }

    
  }

  const getBalance = async () => {
    try {
        const response = await axiosPrivate.get('/user/getme');
        setBalance(response.data.balance.toFixed(2));
    } catch (err) {}
  }

  const buy = async () => {

    if(!selectedChannel){
      toast.error('No channel has been selected.')
      return;
    }

    if(!selectedService){
      toast.error('No service has been selected.')
      return;
    }

    try {
        const response = await axiosPrivate.post('/order/buy',
                    {
                      "channel": selectedChannel,
                      "service": selectedService,
                      "country": selectedCountry
                    });
        setCountry(response.data.country);
        setPhoneNumber(response.data.number);
        setPrice(response.data.price);
        setIdTask(response.data.oid);
        setSeconds(600);
        setStatus("Pending");
        setAutoCancel(false);
        setBuyNumber(true);
        setbuyNumberButton(true);
        setTimeOutCancel(false);

        await getBalance()

        toast.success("Order successful 🤩");
    } catch (err) {
        if (err.response?.status === 404) {
          toast.error('Not numbers avbailable.')
        }else if (err.response?.status === 402) {
          toast.warning('Balance too low. 💰')
        }else{
          navigate('/login', { state: { from: location }, replace: true });
        }
    }
  
  }

  const cancel = async (automatic) => {

    try {
      const response = await axiosPrivate.post('/order/cancel',
                    {"id": idTask});
      toast.success(`Order ${idTask} cancelled.`);
      await getBalance();
    } catch (err) {
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }

    setCodeArrived(false);
    if(automatic) setBuyNumber(false);
    
    setbuyNumberButton(false);
    setStatus("Cancel");
  
  }


  const table = () => {

    return (
          <>
          <table style={tableStyle}>
            <tr key="country" style={cellStyle}>
              <td style={cellStyle}><b>Country:</b></td>
              <td style={cellStyle}>{country}</td>
            </tr>
            <tr key="number" style={cellStyle}>
              <td style={cellStyle}><b>Number:</b></td>
              <td style={cellStyle}>{phoneNumber} {<FaCopy className='icon' onClick={() => copy(phoneNumber)}/>}</td>
              
            </tr>
            <tr key="price" style={cellStyle}>
              <td style={cellStyle}><b>Price:</b></td>
              <td style={cellStyle}>{price}$</td>
            </tr>
            <tr key="status" style={cellStyle}>
              <td style={cellStyle}><b>Status:</b></td>
              <td style={cellStyle}>{status}</td>
            </tr>
            <tr key="code">
              <td style={cellStyle} ><b>Code:</b></td>
              <td style={cellStyle}>
        
                      {!autoCancel && !codeArrived && (
                                <BeatLoader speedMultiplier='0.5' color='#2788e8' style={LoadStyle}/>
                              )}
                      {!autoCancel && codeArrived && (
                          <div>
                          {code} <FaCopy className='icon' onClick={() => copy(code)}/>
                          </div>
                      )}
                      { autoCancel && "- - - -"}
                      
              </td>
            </tr>
          </table>
          
          
          </>
                        
    );
  }

  return (
    <>  
      <center>
        <h1>Select Virtual Numbers</h1>
        <div className="select-container">
        <Select placeholder="Channel 🌐" 
                onChange={handleChannelChange} 
                options={optionsChannel} 
                className="channel" 
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
        </div>
        <div className="select-container">
          <Select placeholder="Choose service" onChange={handleServiceChange} options={optionsService} className="custom-select" />
          <Select placeholder="Random country 🔥" onChange={handleCountryChange} options={optionsCountry} className="custom-select" />
        </div>
        <div className="vnwrapper">
          { buyNumber && (table())}
        </div>
        <div className="vnwrapper">
          { !buyNumberButton && (<button type="submit" className="buy" onClick={buy}>Buy</button>)}
          { buyNumberButton && (
            <>
                    <button type="submit"  className="cancel" onClick={() => cancel(true)}>Cancel</button>
                    <div>
                      <p>Timer: {formatTime(seconds)}</p>
                    </div>
            </>
                    )}
        </div>
      </center>
      
      <ToastContainer position="bottom-right"/>
    </>
  );
};

export default VirtualNumbers;
