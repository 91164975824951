import { MdEmail } from "react-icons/md";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import axios from '../api/axios';

const ResetPassword = () => {

  const [email,setEmail] = useState();
  const [isReset,setIsReset] = useState(false);
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


  const resetEmail = async (e) => {
    e.preventDefault();

    const t0 = EMAIL_REGEX.test(email);

    if(!t0){
      toast.error("Email address invalid. 🤯");
      return;
    }

    try{
      const result = await axios.post(
          '/reset-password',
          {email})
      console.log(result);
      toast.success("Check your email. 🥰");
    }catch(err){
        console.error(err.message);
        return;
    }

    setIsReset(true);


  } 
   
            return (
              !isReset ? (
                <div className="wrapper">
                  <form action="">
                    <h1>Forgot Password</h1>
                    <div className="input-box">
                      <input
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <MdEmail className="icon" />
                    </div>
                    <button type="submit" className="login" onClick={resetEmail}>
                      Reset
                    </button>
                  </form>
                  <ToastContainer position="bottom-right"/>
                </div>
                
              ) : 
              (
                <p>We've sent a link to reset your password to your email. Please check your inbox and follow the instructions to continue.</p>
              )
            );
  
}
export default ResetPassword;
