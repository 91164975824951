import React from 'react';
import './style/Deposit.css'; // Import the CSS file
import usdtImage from "../img/usdt.png";
import tonImage from "../img/ton.png";
import bitcoinImage from "../img/bitcoin.png";
import { Link } from "react-router-dom";



const Deposit = () => {
  return (
    <>
      <div className="deposit-container">
        <div className="link-wrapper">
          <Link to="/usdt">
            <img src={usdtImage} alt="USDT - TRC20" className="hover-image" />
            <p>USDT - TRC20</p>
          </Link>
        </div>
        <div className="link-wrapper">
          <Link to="/ton" >
            <img src={tonImage} alt="TON" className="hover-image" />
            <p>TON</p>
          </Link>
        </div>
        <div className="link-wrapper">
          <Link to="/bitcoin" >
            <img src={bitcoinImage} alt="BITCOIN" className="hover-image" />
            <p>BITCOIN</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Deposit;
