import { useState,useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ReactCountryFlag from "react-country-flag"

const Home = () => {

  const [hotCountries, setHotCountries] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
    padding: '8px',
    fontSize: '25px'
  };
  
  const cellStyle = {
    width: '20%',
    textAlign: 'center',
    padding: '8px'
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('data/hot');
        const hots = response.data;
        let tempHots = []; 
        hots.hots.forEach((hot, index) => {
          tempHots.push(
            <tr key={index}> {/* Use a unique identifier if available instead of index */}
              <td style={cellStyle}>{hot.country} <ReactCountryFlag countryCode={hot.country_code} svg /></td>
              <td style={cellStyle}>{hot.service}</td>
            </tr>
          );
        });        

        setHotCountries(tempHots);

      } catch (error) {
        console.error("There was an error fetching the data:", error);
        // Handle the error appropriately in your UI
      }
    };

    fetchData();
  }, []);

  

          return (
            <>
            <center>
            <div className="home-page" style={{"width": '750px'}}>
              <h1>Get Virtual Numbers for Receive SMS Online 🚀</h1>
              
                <br/>
                <div className="text-home" style={{"textAlign": 'left'}}>
                Use SMSLand for hassle-free SMS verifications and account activations on various platforms without disclosing your personal number. 
                Enjoy the convenience of registering multiple profiles with temporary virtual phone numbers.
                </div>
              </div>
              </center>
              <br/><br/>
              

            </>
            
          );
}
export default Home;

/*
              <h1>Hot Countries 🔥</h1>
              <center>
              <table style={tableStyle}>
                  {hotCountries}
              </table>
              </center>
*/