import { GiCancel } from "react-icons/gi";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';

const Orders = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [orders, setOrders] = useState([]);
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;

  const getOrders = async () => {
    try {
      const response = await axiosPrivate.get('/order');
      setOrders(response.data);
    } catch (err) {
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    getOrders();
  }, [axiosPrivate, navigate, location]);

  useEffect(() => {
    if (orders.length > 0) {
      createTable();
    }
  }, [orders, currentPage]); // Added currentPage as a dependency

  const closeOrder = async (e, oid) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.post('/order/cancel', {"id": oid});
      console.log(response);
      toast(`Order ${oid} cancelled.`);
    } catch (err) {
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
    await getOrders();
    createTable();
  };

  const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
  };

  const cellStyle = {
    border: '1px solid black',
    textAlign: 'center',
    padding: '8px',
  };

  const createTable = () => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = orders.slice(indexOfFirstRow, indexOfLastRow);

    setRows(currentRows.map((order, index) => (
      <tr key={index}>
        <td style={cellStyle}>{order.oid}</td>
        <td style={cellStyle}>{order.service}</td>
        <td style={cellStyle}>{order.country}</td>
        <td style={cellStyle}>{order.number}</td>
        <td style={cellStyle}>{order.price}$</td>
        <td style={cellStyle}>{order.code || '-'}</td>
        <td style={cellStyle}>
          {order.status === "pending" ? (
            <>{order.status} <GiCancel onClick={(e) => closeOrder(e, order.oid)}/></>
          ) : (
            order.status
          )}
        </td>
      </tr>
    )));
  };

  // Handle page changes
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Render page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(orders.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <center>
        <h1>Orders</h1>
        <br/>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Service</th>
              <th>Country</th>
              <th>Number</th>
              <th>Price</th>
              <th>Code</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <center>
        <div className="pages-btm">
          {pageNumbers.map(number => (
            <button key={number} onClick={() => handlePageChange(number)}>
              {number}
            </button>
          ))}
        </div>
        </center>
        <ToastContainer position="bottom-right"/>
      </center>
    </>
  );
}

export default Orders;
