// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

section {
    padding-top: 20px;
    padding: 20px;
    border-bottom: 1px solid #dbd7d7;
}

h2{
    padding-bottom: 10px;
}

h3{
    padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/style/API.css"],"names":[],"mappings":";;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\n\nsection {\n    padding-top: 20px;\n    padding: 20px;\n    border-bottom: 1px solid #dbd7d7;\n}\n\nh2{\n    padding-bottom: 10px;\n}\n\nh3{\n    padding-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
