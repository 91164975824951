import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdAccountCircle } from "react-icons/md";
import { LuShoppingCart } from "react-icons/lu";
import { MdGeneratingTokens } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { GrLogout } from "react-icons/gr";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useBalance from "../hooks/useBalance";
import { useState } from "react";
import useLogout from "../hooks/useLogout"; 

const subMenu = () => {

    const sb = document.getElementById('subMenu');
    sb.classList.toggle('open-menu');
}

const Navbar = () => {
    const { auth } = useAuth();

    const logout = useLogout();

    const {balance, setBalance} = useBalance();
    const [username, setUsername] = useState();

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const getBalance = async () => {
        try {
            const response = await axiosPrivate.get('/user/getme');
            setBalance(response.data.balance.toFixed(2));
            setUsername(response.data.username);
        } catch (err) {
            console.error(err);
            logout();
            navigate('/login', { state: { from: location }, replace: true });
        }
    }

    const signOut = async () => {
        await logout();
    }
    

    const navBarContent = () => {
        
        
        if (auth?.accessToken) {
            getBalance();
            return (
                <>
                
                <div className="links">
                    <Link to="/">Home</Link>
                    <Link to="/deposit">Deposit</Link>
                    <Link to="/virtualnumbers">Virtual Numbers</Link>
                    <Link to="/api">API</Link>
                </div>
                
                <div className="username-sub-menu" onMouseEnter={subMenu} onMouseLeave={subMenu}>
                    <div className="username"  >
                        <MdKeyboardArrowDown className='account' />
                        {username}
                        <MdAccountCircle className='icon'/>
                    </div>

                    <div className="sub-menu-wrap" id="subMenu" >
                            <div className="sub-menu">
                                <div className="user-info">
                                    <HiOutlineCurrencyDollar className="user-inf-ico" />
                                    {balance}$<br/>
                                    <hr/>
                                    <LuShoppingCart  className='user-inf-ico'/>
                                    <Link to="/orders" >Orders</Link> <br/>
                                    <hr/>
                                    <MdGeneratingTokens className='user-inf-ico' />
                                    <Link to="/token" >Token</Link> <br/>
                                    <hr/>
                                    <IoSettings className='user-inf-ico' />
                                    <Link to="/settings" >Settings</Link> <br/>
                                    <hr/>
                                    <div className="logout">
                                        <GrLogout  className='user-inf-ico'/>
                                        <Link className="logout" to="/login" onClick={signOut}>Logout</Link>
                                    </div>
                                </div>
                            </div>
                    </div>

                </div>

                </>
            );
        } else {
            return (
                <>
                    <div className="links">
                        <Link to="/">Home</Link>
                        <Link to="/api">API</Link>
                        <Link to="/login">Login</Link>
                    </div>
                    <div className="signup">
                        <Link to="/signup">Sign Up</Link>
                    </div>
                </>
            );
        }
    };

    return (
        <div className="navbar">
            <h1>SMSLand.org</h1>
            {navBarContent()}
        </div>
    );
};

export default Navbar;
